/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';
//Stylsheets für Slider
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';

@import "~leaflet/dist/leaflet.css";
@import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css";

@font-face {
    font-family: 'Gunny Rewritten';
    src: url('assets/fonts/gunny-rewritten.regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  


  /* Setze die Schriftart für Überschriften auf Open Sans */
  h1, h2, h3, h4, h5, h6,p,li,body, ion-label,ion-breadcrumb,ion-icon,ion-card-title {
    font-family: 'Open Sans', sans-serif;
  }
  

h1,ion-title{
    background-color: #0070b8;
   // background: //#2596be 0% 0% ;
    opacity: 1;
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    padding-left:20px;
    padding:10px;
    font-size: 30px;
}
#kaerntenlogo{
  //  padding-left: 20%;
    height: 45px;  
}

@media (max-width: 400px) {
    ion-title {
      font-size: clamp(23px, 2vw, 30px);
    }
  
    #kaerntenlogo {
     
      height: clamp(35px, 3vw, 45px);
    }
}
//für Unterüberschrift
h2{
    color: #0073A5;
   
    font-weight: normal;
    font-size: 22px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform:none;
}
//Unterseiten Überschrift
h3 {
    color: #0073A5;
   
    font-weight: normal;
    font-size: 24px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform: uppercase;
}
//für kurzinfo blöcke
h4{
    color: white;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    text-transform: uppercase;

}
//für Überschriften im text
h5{
    text-transform: uppercase;
    font-size: 20px;
    color:black;
}
.socialmedia{
    margin:20px;
}
.card-picture{
    height: 300px;
    width:300px;
    object-fit: cover;
    object-position: top center;   
}
.card-picture-form{
    width:300px;
    height:300px;
}
.bottom-right {
    position: absolute;
    bottom: 4px;
    right: 10px;
    color:white;
    font-size: 9px;
    border-style:solid;
    border-width: 1px;
    border-color:white;
    padding:2px;
    }

.bottom-middle-top {
    position: absolute;
    top: 70%;
    left: 25%;
    color:white;
    text-transform: uppercase;     
    font-weight: bold; 
    text-align: center;
} 
.top-left {
    position: absolute;
    top: 5%;
    left: 2.5%;
    color:white;
    text-transform: uppercase;     
    font-weight: bold; 
    text-align: center;
} 
.middle-top {
    position: absolute;
    top: 5%;
    color:white;
    text-transform: uppercase;     
    font-weight: bold; 
    //text-align: center;
}
.unterkuenfte {
  position: absolute;
  top: 15%;
  left: 10%;
  color:white;
  text-transform: uppercase;     
  font-weight: bold; 
  //text-align: center;
}  
.bottom-middle-bottom {
    position: absolute;
    top: 75%;
    left: 25%;
    color:white;    
    text-transform: uppercase; 
    font-weight: bold; 
}  
.einleitungstext{
    margin-left:20px;
    margin-right:20px;
   

}
.kurzinfo{
    background-color: #0070b8;;
    color: white;
    padding:30px;
   
}   
.einleitungsbild{ 
    height:300px;
    width:100%;
    object-fit: cover;
    object-position: top center;
    
} 
svg{
   position:sticky;
    object-position: 100px;
}
.bottom{
    overflow: hidden;
    z-index: 2;
    position: relative;
    top:0;
}
p,h5{
    margin-left:20px;
    margin-right:20px;
}
.NewsCard{
    background-color:#2596be;
    color:white;
    height: 400px;
    width: 100%;
    margin-top:20px;
    padding-bottom: 0px;
    padding-top:0px;
    border-radius: 0px;

}
.NewsCard{
  
    background-color: #2596be;
   

    #NewsCardImage{
        
        width:100%;
        height:170px;
        object-fit: cover;
    object-position: top center;
    padding-bottom:0px;
        
    }
}
ion-breadcrumb,ion-icon,ion-card-title{
  padding-top:0px;
  padding-bottom:0px;
    color:white;
}

ion-card-title{
    padding-left: 15px;
    padding-top:10px;
    padding-bottom:10px;
}
ion-breadcrumb,ion-icon{
  
    font-size: 13px;
}
//Start Wellen
.wrapper{
    position: relative;
    background-color: white;
    
}


//Ende wellenformat

//Buttons
.buttonScroller {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    text-align: center;

    .scrollerElement {
        display: inline-block;
    }
}

.buttonGroup {
    display: flex;
    align-items: center;
    justify-content: center;
   
}
ion-button {
    
    margin:5px;
    height:120px;
    width: 150px;
    --color: white;
    --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
    --border-radius: 20px;
} 

 @-webkit-keyframes roll-in-left{0%{-webkit-transform:translateX(-800px) rotate(-540deg);transform:translateX(-800px) rotate(-540deg);opacity:0}100%{-webkit-transform:translateX(0) rotate(0deg);transform:translateX(0) rotate(0deg);opacity:1}}@keyframes roll-in-left{0%{-webkit-transform:translateX(-800px) rotate(-540deg);transform:translateX(-800px) rotate(-540deg);opacity:0}100%{-webkit-transform:translateX(0) rotate(0deg);transform:translateX(0) rotate(0deg);opacity:1}}
 .roll-in-left{-webkit-animation:roll-in-left .6s ease-out both;animation:roll-in-left .6s ease-out both}
@-webkit-keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}@keyframes tracking-in-expand{0%{letter-spacing:-.5em;opacity:0}40%{opacity:.6}100%{opacity:1}}
.tracking-in-expand{-webkit-animation:tracking-in-expand .7s cubic-bezier(.95,.05,.795,.035) 2s both;animation:tracking-in-expand .7s cubic-bezier(.95,.05,.795,.035) 2s both}


 @-webkit-keyframes fade-in{0%{opacity:0}100%{opacity:1}}@keyframes fade-in{0%{opacity:0}100%{opacity:1}}
.fade-in{-webkit-animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both;animation:fade-in 1.2s cubic-bezier(.39,.575,.565,1.000) both}

//für Datum
.text-box {
    border: 1px solid #ddd;
    padding: 5px;
   margin: 5px;
    border-top: 5px solid #0070b8;;
    width: 50px;
    text-align: center;
  }
  .date-true-box {
    
    border: 1px solid #ddd;
    padding: 5px;
   margin: 5px;
    border-top: 5px solid green;
    width: 50px;
    text-align: center; 
  }
  .date-false-box {
    border: 1px solid #ddd;
    padding: 5px;
    margin: 5px;
    border-top: 5px solid red;
    width: 50px;
    text-align: center;
  }
  
    
//für Icons bei Unterseiten
.icon-colored{
    color: black;
    padding-right: 5%;
}
//für Wellenformat oben und content background = white

.image-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    margin-top: 0px;
    padding: 20px;
    background-color: white;
  }
  .einleitungscontainer{
    height:300px;
  }
  body{
    background-color: none;
  }
  
  .oben {
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
   
}

.oben svg {
    position: relative;
    display: block;
    width: calc(127% + 1.3px);
    height: 147px;
    margin-top: 200px;
   border-width: 20px;
}

.oben .shape-fill {
    fill: white;
    
}

/** For mobile devices **/
@media (max-width: 767px) {
    .oben svg {
        width: calc(139% + 1.3px);
        height: 92px;
    }
}
//schreibende schrift
/*ion-title {
    
    overflow: hidden;
  
    word-wrap: break-word;
    white-space: nowrap;
    animation: typing 1.6s steps(70) forwards;
  } 
  
  @keyframes typing {
     from {
        width: 0;
     }
     to {
        width: 20ch;
     }
  }*/

  //list
  li {
    list-style-type: none;
  }
  li:before {
   // content: "•";
    color: red;
    margin-right: 0.5em;
  }
  .center{
    display: flex;
    flex-flow: column wrap;
    background-color: none;
    justify-content:center;
    align-items:center;
  
}
//für touren
.mittel {
    background-color: white;
    border-width: 2px;
    color: red;
    text-transform: uppercase;
    padding: 2px;
    border: 1px solid red;
    padding: 5px;
    text-align: center;
  }
  .schwer {
    background-color: white;
    border-width: 2px;
    color: black;
    text-transform: uppercase;
    padding: 2px;
    border: 1px solid black;
    padding: 5px;
    text-align: center;
  }
  .leicht {
    background-color: white;
    border-width: 2px;
    color: green;
    text-transform: uppercase;
    padding: 2px;
    border: 1px solid green;
    padding: 5px;
    text-align: center;
  }
  a{
    text-align: center;
    flex-flow: column wrap;
    font-family: "Open Sans";
    align-items:center;
    justify-content:center;
    text-align: center;
  }
  button{
    margin:10px;
    padding:10px;
    display: flex;
    font-family: "Open Sans";
    align-items:center;
    justify-content:center;
    text-align: center;
  }

  .title-default {
    width: 100vw;
  }